<template>
  <div class="candle-note relative">
    <div v-if="note !== null">
      <b-form-textarea
        v-model="note"
        placeholder="Note"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
      <div
        v-if="isSaving"
        class="spinner-container"
      >
        <b-spinner small label="Small Spinner"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');

export default {
  name: 'CandleFiles',
  components: {},
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isSaving() {
      return this.savingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      note: null,
      scheduleSaving: _.debounce(() => {
        this.updateNote();
      }, 250),
    };
  },
  methods: {
    updateNote() {
      this.savingCount++;
      const changes = {
        candle_note: this.note,
      };
      this.$http
        .put(`/candle/${this.id}`)
        .send({ changes })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.$toast.error(`Failed to update note: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
        });
    },
    fetchNote() {
      this.note = null;
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}`)
        .then((res) => {
          this.note = res.body.candle_note;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.fetchNote();
      },
    },
    note: {
      handler(after, before) {
        if (after === null || before === null) {
          return;
        }
        this.scheduleSaving();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.candle-note .spinner-container {
  position: absolute;
  top: 10px;
  right: 25px;
}
</style>
